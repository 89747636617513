.profile__container {
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.profile__container .user {
    font-size: 40px;
    margin-bottom: 5px;
    padding: 10px;
}

.profile__container .signout button {
    font-size: 1vw;
    margin-top: 20px;
    padding: 10px;
    background-color: gainsboro;
    border: 1px gray solid; 
}

.profile__container .signout button:hover {
    cursor: pointer;
}

.features__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
    row-gap: 20px;
    padding: 5px;
}

.features__container>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
    padding: 10px;
    border-radius: 5px;
    width: 60%;
}

.features__container div:hover {
    color: rgb(192, 200, 215);
    cursor: pointer;
}

.homeIcon {
    position: fixed;
    top: 20px;
    left: 20px;
}

.homeIcon:hover {
    cursor: pointer;
}

.profile__container .diamond {
    display: flex;
    flex-direction: row;
    font-size: 30px;
    column-gap: 10px;
}

.loader__container {
    text-align: center;
    margin-top: 40px;
}

@media only screen and (max-width: 600px) {
    .profile__container .user {
        font-size: 30px;
    }

    .profile__container .signout button {
        font-size: 15px;
    }

    .features__container {
        font-size: 15px;
        overflow: hidden;
    }
}

.account__settings {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: row;
    column-gap: 20px;
}

.account__settings button {
    padding: 10px;
}

.delete__account__button {
    border: none;
    background-color: rgb(167, 48, 48);
    color: white;
}

.delete__account__button:hover {
    cursor: pointer;
}

.clear__data__button {
    border: none;
    background-color: rgb(215, 146, 20);
    color: rgb(18, 17, 17);
}

.clear__data__button:hover {
    cursor: pointer;
}