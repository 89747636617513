.leaderboard__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(238, 237, 237);
    padding-bottom: 200px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.leaderboard__container .header {
    font-size: 30px;
    font-weight: 500;
    margin: 30px;
}

.leaderboard__container .users {
    display: flex;
    font-size: 18px;
    width: 80%;
    flex-direction: column;
    row-gap: 10px;
}

.leaderboard__container .users > div {
    border: 1px solid gray;
    border-radius: 10px;
    padding: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.leaderboard__container .users .each_user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.leaderboard__container .users .each_user .diamond {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.leaderboard__container .users .each_user .diamond img{
    height: 22px;
    margin-left: 5px;
}

.leaderboard__container .users .current__user {
    border: 2px solid rgb(137, 137, 137);
    background-color: rgb(232, 232, 232);
    color: rgb(29, 29, 29);
    font-weight: 600;
}

.cp {
    cursor: pointer;
}

.cp:hover {
    color: rgb(103, 103, 103);
}

@media screen and (max-width: 600px) {
    
}

@media screen and (max-width: 1200px) and (min-width: 600px)  {

}
