.features__container {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-top: 20px;
}

.features__container div {
    width: 40%;
    font-size: 20px;
    height: 40px;
    background-color: rgb(42, 131, 182);
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-center {
    text-align: center;
}

.feature__heading {
    margin-top: 40px;
}

.logo__container__2 {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    margin-top: 30px;
}

.logo__container__2 img {
    border-radius: 2px solid rgb(221, 221, 221);
    height: 130px;
}

.grid-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    gap: 12px;
    padding-top: 40px;
    padding-left: 5vw;
    padding-right: 5vw;
}

.grid-item {
    padding: 20px;
    border: 1px solid #c9c8c8;
    text-align: center;
    background-color: rgb(249, 249, 249);
    font-size: 15px;
    border: 1px solid grey;
    border-radius: 20px;
}

.grid-container .grid-item img {
    height: 80px;
}

.grid-item {
    position: relative;
}

.feature__text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    font-size: 20px;
}
