.main__outer__container {
    /* background-color: rgb(244, 242, 241); */
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.quiz__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0rem 1rem 0rem;
    padding: 1rem 2rem 5rem;
    user-select: none; /* Standard syntax */
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    border: 0.6px solid grey;
    background-color: rgb(46, 48, 50);
    border-radius: 5px;
    color: white;
}

.explanation__container {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.quiz__container .question__container {
    font-size: 1.3vw;
    margin-bottom: 10px;
}

.quiz__container .topic__container {
    margin-bottom: 1.5rem;
}

.quiz__container .topic__container .topic__text {
    padding: 5px 15px;
    border-radius: 10px;
    color: rgb(17, 16, 16);
}

.quiz__container .answer__container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 1vw;
    width: 100%;
}

.quiz__container .answer__container div {
    box-shadow: 0 0 0 0.5px #52585c, 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.quiz__container .answer__container .eachAnswer {
    border: 1.2px solid gray;
    padding: 10px;
}

.quiz__container .answer__container .correctAnswer {
    border: 1px solid gray;
    padding: 10px;
    background-color: rgb(128, 224, 128);
    color: rgb(38, 36, 36);
}

.quiz__container .answer__container .wrongAnswer {
    border: 1px solid gray;
    padding: 10px;
    background-color: rgb(230, 120, 113);
    color: rgb(30, 27, 27);
}

.quiz__container .answer__container .chosenOption {
    border: 1px solid gray;
    padding: 10px;
    background-color: white;
    color: rgb(39, 37, 37);
}

.quiz__container .answer__container .eachAnswer:hover {
    cursor: pointer;
    background-color: white;
    color: rgb(41, 39, 39);
}

.quiz__container .submit__container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-top: 20px;
    width: 100%;
}

.quiz__container .submit__container .submitBtn {
    border: 1px solid gray;
    padding: 10px;
    background-color: rgb(61, 120, 230);
    color: white;
    text-align: center;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 5px;
}

.quiz__container .submit__container .submitBtn:hover {
    cursor: pointer;
}

.explanation__container {
    margin-top: 20px;
    user-select: none;
}

.restart__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}


.restart__container .restartIcon:hover {
    cursor: pointer;
    color: rgb(49, 135, 210);
    scale: 1.3;
}

.quiz__container .loader__container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.quiz__container .loading__container {
    text-align: center;
}

/*  Fact Container  */
.main__outer__container .fact__container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin: 0rem 3rem 2rem;
}

.main__outer__container .fact__container .each__fact__container{
    background-color: rgb(46, 48, 50);
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    border-radius: 10px;
}

.main__outer__container .fact__container .each__fact__container:hover{
    background-color: rgb(68, 71, 74);
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .quiz__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 1rem;
        padding: 1rem;
        user-select: none; /* Standard syntax */
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        border: 0.6px solid grey;
        background-color: rgb(46, 48, 50);
        border-radius: 5px;
        color: white;
    }

    .main__outer__container .fact__container {
        display: flex;
        row-gap: 10px;
        margin: 1rem;
    }

    .quiz__container .question__container {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .quiz__container .answer__container .eachAnswer {
        font-size: 15px;
    }

    .quiz__container .answer__container .correctAnswer {
        border: 1px solid gray;
        font-size: 15px;
    }

    .quiz__container .answer__container .wrongAnswer {
        font-size: 15px;
    }

    .quiz__container .answer__container .chosenOption {
        font-size: 15px;
    }

    .quiz__container .topic__container {
        display: none;
    }
}

@media screen and (max-width: 1200px) and (min-width: 600px)  {
    .quiz__container .question__container {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .quiz__container .answer__container .eachAnswer {
        font-size: 24px;
    }

    .quiz__container .answer__container .correctAnswer {
        border: 1px solid gray;
        font-size: 24px;
    }

    .quiz__container .answer__container .wrongAnswer {
        font-size: 24px;
    }

    .quiz__container .answer__container .chosenOption {
        font-size: 24px;
    }
}

