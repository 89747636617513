/* Add this CSS to your stylesheets or use a styled-components approach */

/* Hide the default scrollbar */
::-webkit-scrollbar {
    width: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #333; /* Set the background color of the track */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e0dbdb; /* Set the color of the scrollbar handle */
    border-radius: 6px; /* Set the border-radius of the scrollbar handle */
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #777;
  }
  