.rewards__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    font-size: 20px;
    margin-top: 20px;
    padding-left: 3vw;
    padding-right: 3vw;
    background-color: rgb(238, 237, 237)
}

.rewards__container .header__text {
    font-size: larger;
    font-weight: 900;
}

.rewards__container .rewards__list__container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}