.header__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 0 15px;
    font-size: 22px;
    font-weight: 500;
    height: 6vh;
    background-color: rgb(48, 48, 48);
    color: white;
}
.cursor-pointer {
    cursor: pointer;
}