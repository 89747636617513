.daily__quiz__set__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 300px;
    row-gap: 30px;
    margin-left: 4vh;
    margin-right: 4vh;
    font-size: 20px;
}

.daily__quiz__set__container .quit__set__container {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.daily__quiz__set__container .quit__set__container .options__container {
    display: flex;
    flex-direction: column;
}

.daily__quiz__set__container .quit__set__container .options__container .option__text {
    border: 1px solid gray;
    padding: 5px;
    margin: 5px;
}