.pdf__main__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pdf__main__container .pdf__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 20px;
    border: 1px solid gray;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: rgb(235, 239, 243);
}

.pdf__main__container .pdf__container:hover {
    cursor: pointer;
}

.pdf__main__container .pdf__container .pdf__name {
    margin-right: 40px;
}

.pdf__main__container .pdf__container .pdf__icon img {
    height: 40px;
}